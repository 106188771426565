import React from 'react';
import Img from 'gatsby-image';

import { Row, Tile } from './AboutUsRowStyled';
import P from 'components/P';
import { AboutUsRowProps, TextOnTheRightProps } from './types';

const AboutUsRow: React.FC<AboutUsRowProps & TextOnTheRightProps> = ({
  text,
  textOnTheRight,
  picture,
  alt,
}) => (
  <Row>
    <Tile textOnTheRight={textOnTheRight}>
      <P textAlign="justify">{text}</P>
    </Tile>
    <Tile>
      <Img fluid={picture.childImageSharp.fluid} alt={alt} />
    </Tile>
  </Row>
);

export default AboutUsRow;
