import styled from 'styled-components';

import BREAKPOINTS from 'css/breakpoints';
import { TextOnTheRightProps } from './types';

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    margin-bottom: 30px;
    flex-direction: row;
  }
`;

export const Tile = styled.div<TextOnTheRightProps>`
  width: 100%;
  margin-bottom: 25px;
  order: 1;

  ${(props) =>
    props.textOnTheRight &&
    `
    order: 1;

    @media (min-width: ${BREAKPOINTS.md.min}px) {
      width: calc(100%/2 - 30px/2);
      margin-bottom: 0;
      order: 2;
    }
  `}

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    width: calc(100% / 2 - 30px / 2);
    margin-bottom: 0;
  }
`;
