import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import Layout from 'components/Layout';
import Section from 'components/Section';
import AboutUsRow from 'components/AboutUsRow';

type RowProps = {
  alt: string;
  copy: string;
  picture: string;
};

type PicsDictProps = {
  [key: number]: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
};

const title =
  'Motoroma | O nas - warsztat, naprawa motocykli, quad, mechanika, wulkanizacja - Jarocin';
const description =
  'Działamy na rynku od 2012 roku w Witaszyczkach k/Jarocina. Współpracujemy z autoryzowanym serwisem BMW w zakresie handlu nowymi częściami zamiennymi.';

const AboutPage = () => {
  const { markdownRemark, about1, about2, about3 } = useStaticQuery(
    graphql`
      query AboutUsQuery {
        markdownRemark(fileAbsolutePath: { regex: "/about_us_page/" }) {
          frontmatter {
            about_us_page_rows {
              alt
              copy
              picture
            }
          }
        }
        about1: file(relativePath: { eq: "about_us_1.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        about2: file(relativePath: { eq: "about_us_2.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        about3: file(relativePath: { eq: "about_us_3.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  const picsDict: PicsDictProps = {
    0: about1,
    1: about2,
    2: about3,
  };

  return (
    <Layout
      smallHero
      title="O nas"
      slug="/about"
      metaTitle={title}
      metaDescription={description}
    >
      <Section withoutHeader $noButton>
        {markdownRemark.frontmatter.about_us_page_rows.map(
          (row: RowProps, index: number) => (
            <AboutUsRow
              key={row.copy}
              text={row.copy}
              alt={row.alt}
              textOnTheRight={index % 2 === 0}
              picture={picsDict[index]}
            />
          )
        )}
      </Section>
    </Layout>
  );
};

export default AboutPage;
